require("jquery");

class RegistrationFormManager {
    constructor() {
        console.log('construct')
    }

    init() {
        this.calculateFee();
        this.attachHandlers();
    }

    attachHandlers() {
        this.calculateFee();
        $('input[type="checkbox"]').on('change', (e) => {
            this.calculateFee();
        })

        this.switchType();
        $('input[data-switch-type]').on('change', (e) => {
            this.switchType();
        })


    }

    calculateFee() {
        $('[data-all-fees] span').hide();
        if ($('[data-membership]').is(':checked') && $("[data-reduced-fee]").is(':checked')) {
            $('[data-fee-member-reduced]').show();
        } else if ($("[data-reduced-fee]").is(':checked')) {
            $('[data-fee-reduced]').show();
        } else if ($("[data-membership]").is(':checked')) {
            $('[data-fee-member]').show();
        } else {
            $('[data-fee-default]').show();
        }
    }

    switchType() {
        if ($('input[data-switch-type="presence"]:checked').length) {
            $('[data-visible-presence]').removeClass('hidden').find('input').prop('disabled',false);;
            $('[data-visible-online]').addClass('hidden').find('input').prop('disabled',true);
        } else if ($('input[data-switch-type="online"]:checked').length) {
            $('[data-visible-presence]').addClass('hidden').find('input').prop('disabled',true);;
            $('[data-visible-online]').removeClass('hidden').find('input').prop('disabled',false);;
        }

    }
}

document.addEventListener("turbolinks:load", function() {
    const reg = new RegistrationFormManager();
    reg.init();
})
